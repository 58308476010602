<template>
    <div class="user_account">
        <div>
            <el-button @click="openWorkWeixinUrl" type="primary">绑定企业微信</el-button>
            <el-button @click="queryStaffWxcpApi" type="primary" plain icon="el-icon-refresh">更新列表</el-button>
        </div>
        <br />
        <el-table :data="workWxList" border style="width: 100%" highlight-current-row>
            <el-table-column label="绑定企业微信ID" prop="cid" />
            <el-table-column label="企业微信昵称" prop="name" />
            <el-table-column width="80" align="center" label="操作">
                <template slot-scope="scope">
                    <el-popconfirm @confirm="onDelAccount(scope.row)" title="确定删除吗?">
                        <el-link type="danger" slot="reference">删除</el-link>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import _config from '@/js/config.js'
import { mapGetters } from 'vuex'
import { queryStaffWxcp, unbindWxCp } from '@/js/api/staffApi.js'

export default {
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            workWxList: [],
        }
    },
    created() {
        this.queryStaffWxcpApi()
    },
    methods: {
        onDelAccount(item) {
            unbindWxCp({ staffId: this.userInfo.id, cid: item.cid }).then(() => {
                this.$message.success('删除成功')
                this.queryStaffWxcpApi()
            })
        },
        openWorkWeixinUrl() {
            const _url = encodeURIComponent(`${window.location.origin}/#/weComAuth`)
            const _params = `appid=${_config.weComAppId}&agentid=${_config.weComAgentid}&redirect_uri=${_url}&state=STATE`
            window.open(`https://open.work.weixin.qq.com/wwopen/sso/qrConnect?${_params}`, '_blank')
        },
        queryStaffWxcpApi() {
            queryStaffWxcp({ staffId: this.userInfo.id }).then((res) => {
                this.workWxList = res.data
            })
        },
    },
}
</script>
