<template>
    <div>
        <div class="opts">
            <ProjectSelect @change="onProjectNoChange" />

            <el-button type="primary" @click="onShowEditStaffQrcode(null)">添加接量码</el-button>
        </div>
        <br />
        <el-table :data="tableData" border stripe>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" label="微信号" prop="wxAccount" />
            <el-table-column align="center" label="微信昵称" prop="wxNickname" />
            <el-table-column align="center" label="二维码">
                <template slot-scope="scope">
                    <el-image
                        class="qrcode_img"
                        :preview-src-list="[scope.row.qrcodeUrl]"
                        :src="scope.row.qrcodeUrl"
                        fit="cover"
                    />
                </template>
            </el-table-column>
            <el-table-column align="center" label="使用状态" width="100">
                <template slot-scope="scope">
                    <el-tag effect="dark" v-if="scope.row.currentFlag" type="success">使用中</el-tag>
                    <el-tag effect="dark" v-else type="info">未使用</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="80">
                <template slot-scope="scope">
                    <el-link type="primary" @click="onShowEditStaffQrcode(scope.row)">编辑信息</el-link>
                    <el-popconfirm
                        title="确定使用吗？"
                        v-if="!scope.row.currentFlag"
                        @confirm="enableStaffQrcodeApi(scope.row)"
                    >
                        <el-link slot="reference">立即使用</el-link>
                    </el-popconfirm>
                    <el-popconfirm
                        title="确定删除吗？"
                        v-if="!scope.row.currentFlag"
                        @confirm="removeStaffQrcodeApi(scope.row)"
                    >
                        <el-link type="danger" slot="reference">删除微信</el-link>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <EditStaffQrcode
            :projectNo="projectNo"
            :isShow="isShowEditStaffQrcode"
            :activeQrcode="activeQrcode"
            @onClose="onCloseEditStaffQrcode"
        />
    </div>
</template>
<script>
import { enableStaffQrcode, removeStaffQrcode, getStaffQrcode } from '@/js/api/staffApi.js'
import { mapGetters } from 'vuex'
import EditStaffQrcode from './EditStaffQrcode.vue'
import ProjectSelect from '@/views/components/ProjectSelect'

export default {
    computed: { ...mapGetters(['userInfo']) },
    components: { ProjectSelect, EditStaffQrcode },
    data() {
        return {
            projectNo: '',

            tableData: [],

            activeQrcode: null,
            isShowEditStaffQrcode: false,
        }
    },
    methods: {
        onProjectNoChange(val) {
            this.projectNo = val
            this.getStaffQrcodeApi()
        },

        // 编辑接量码
        onShowEditStaffQrcode(item) {
            this.activeQrcode = item
            this.isShowEditStaffQrcode = true
        },
        onCloseEditStaffQrcode(state) {
            this.activeQrcode = null
            this.isShowEditStaffQrcode = false
            if (state) this.getStaffQrcodeApi()
        },

        getStaffQrcodeApi() {
            getStaffQrcode({ staffIds: [this.userInfo.id], projectNo: this.projectNo }).then((res) => {
                this.tableData = res.data
            })
        },

        // 开启二维码使用
        enableStaffQrcodeApi(item) {
            enableStaffQrcode({ staffId: this.userInfo.id, qrcodeId: item.id }).then(() => {
                this.$message.success('编辑成功')
                this.getStaffQrcodeApi()
            })
        },
        removeStaffQrcodeApi(item) {
            removeStaffQrcode({ staffId: this.userInfo.id, qrcodeId: item.id }).then(() => {
                this.$message.success('删除成功')
                this.getStaffQrcodeApi()
            })
        },
    },
}
</script>
<style lang="less" scoped>
.opts {
    display: flex;
    justify-content: space-between;
}
.qrcode_img {
    width: 120px;
}
</style>
