<template>
    <el-form ref="formData" :model="formData" label-width="90px">
        <el-form-item label="编号">
            <el-input disabled v-model="staffInfo.staffNo" />
        </el-form-item>
        <el-form-item label="用户名称">
            <el-input disabled v-model="staffInfo.name" />
        </el-form-item>
        <el-form-item label="登录账号">
            <el-input disabled v-model="staffInfo.telNo" />
        </el-form-item>
        <el-form-item label="直播间昵称">
            <el-input v-model.trim="formData.nickname" placeholder="请输入直播间昵称" />
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmitNickname">修改昵称</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import { mapGetters } from 'vuex'
import { adminModStaffPassword } from '@/js/api/staffApi.js'

export default {
    computed: { ...mapGetters({ staffInfo: 'userInfo' }) },
    data() {
        return {
            formData: {
                nickname: '',
            },
        }
    },
    created() {
        this.formData.nickname = this.staffInfo.nickname
    },
    methods: {
        onSubmitNickname() {
            if (!this.formData.nickname) {
                return this.$message.error('请输入用户昵称')
            }
            adminModStaffPassword({
                nickname: this.formData.nickname,
                id: this.staffInfo.id,
            }).then(() => {
                this.$store.dispatch('user/login', { ...this.staffInfo, nickname: this.formData.nickname })

                this.$message.success('修改成功')
            })
        },
    },
}
</script>
