<template>
    <el-dialog
        title="编辑接量码"
        :append-to-body="true"
        :visible.sync="isShow"
        width="480px"
        :before-close="onClose"
        @opened="onOpened"
    >
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="100px">
            <el-form-item label="企业微信" prop="wxid">
                <el-select v-model="formData.wxid" placeholder="请选择" @change="onWxidChange">
                    <el-option
                        v-for="item in workWxList"
                        :key="item.cid"
                        :label="`${item.name}-${item.cid}`"
                        :value="item.cid"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="微信昵称" prop="wxNickname">
                <el-input :disabled="true" placeholder="请输入" v-model="formData.wxNickname" />
            </el-form-item>
            <el-form-item label="微信号" prop="wxAccount">
                <el-input :disabled="true" placeholder="请输入" v-model="formData.wxAccount" />
            </el-form-item>

            <el-form-item label="接量二维码" prop="qrcodeUrl">
                <el-upload
                    class="upload_image"
                    action=""
                    accept="image/*"
                    :show-file-list="false"
                    :before-upload="onQrcodeUpload"
                >
                    <i v-if="!formData.qrcodeUrl" class="el-icon-picture" />
                    <el-image style="height: auto" v-else :src="formData.qrcodeUrl" fit="contain" />
                </el-upload>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { imageCompressor, UPLOAD_FILE } from '@/js/aliOss.js'
import { saveStaffQrcode, queryStaffWxcp } from '@/js/api/staffApi.js'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'activeQrcode', 'projectNo'],
    data() {
        return {
            workWxList: [],
            formData: null,
            rules: {
                wxid: [{ required: true, message: '请选择', trigger: 'change' }],
                wxNickname: [{ required: true, message: '请输入', trigger: 'blur' }],
                wxAccount: [{ required: true, message: '请输入', trigger: 'blur' }],
                qrcodeUrl: [{ required: true, message: '请上传', trigger: 'blur' }],
            },
        }
    },
    computed: { ...mapGetters(['userInfo']) },
    methods: {
        onOpened() {
            let formData = {
                projectNo: this.projectNo,
                wxid: '',
                wxNickname: '',
                wxAccount: '',
                qrcodeUrl: '',
                staffId: this.userInfo.id,
            }
            if (this.activeQrcode) {
                formData = { ...formData, ...this.activeQrcode }
            }
            this.formData = formData
            this.queryStaffWxcpApi()
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },

        onWxidChange() {
            this.workWxList.forEach((item) => {
                if (item.cid === this.formData.wxid) {
                    this.formData.wxAccount = item.cid
                    this.formData.wxNickname = item.name
                }
            })
        },
        onQrcodeUpload(file) {
            imageCompressor(file).then((_file) => {
                UPLOAD_FILE({
                    bucket: 'res',
                    file: _file,
                    path: 'resource/',
                    success: (res) => {
                        this.formData.qrcodeUrl = res.url
                        this.$refs.formData.validateField(['qrcodeUrl'])
                    },
                })
            })
            return false
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    const _data = { ...this.formData }
                    this.workWxList.forEach((i) => {
                        if (_data.wxid === i.cid) {
                            _data.appId = i.corpId
                        }
                    })
                    saveStaffQrcode(_data).then(() => {
                        this.onClose()
                        this.$emit('onClose', true)
                    })
                }
            })
        },
        queryStaffWxcpApi() {
            queryStaffWxcp({ staffId: this.userInfo.id }).then((res) => {
                this.workWxList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-select {
    width: 100%;
}
</style>
