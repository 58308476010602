<template>
    <el-drawer
        class="staff_info"
        title="个人中心"
        :visible.sync="isShow"
        :before-close="onClose"
        size="800px"
        @opened="onOpened"
    >
        <el-tabs v-model="activeName">
            <el-tab-pane v-for="item in tabList" :key="item.val" :label="item.name" :name="item.val">
                <Info v-if="item.val === 'info' && activeName === item.val" />
                <Password v-else-if="item.val === 'password' && activeName === item.val" />
                <WeCom v-else-if="item.val === 'weCom' && activeName === item.val" />
                <Qrcode v-else-if="item.val === 'qrcode' && activeName === item.val" />
            </el-tab-pane>
        </el-tabs>
    </el-drawer>
</template>
<script>
import Info from './Info.vue'
import Password from './Password.vue'
import WeCom from './WeCom.vue'
import Qrcode from './Qrcode.vue'

export default {
    props: ['isShow'],
    components: { Info, Password, WeCom, Qrcode },
    data() {
        return {
            activeName: 'info',
            tabList: [
                { name: '基本信息', val: 'info' },
                { name: '修改密码', val: 'password' },
                { name: '企业微信配置', val: 'weCom' },
                { name: '接量配置', val: 'qrcode' },
            ],
        }
    },
    methods: {
        onOpened() {
            this.activeName = 'info'
        },
        onClose() {
            this.$emit('update:isShow', false)
        },
    },
}
</script>
<style lang="less" scoped>
.staff_info {
    /deep/ .el-drawer__header {
        margin-bottom: 20px;
    }
}
</style>
