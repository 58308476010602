<template>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="100px">
        <el-form-item label="旧密码:" prop="oldPass">
            <el-input size="medium" placeholder="请输入旧密码" show-word-limit v-model.trim="formData.oldPass" />
        </el-form-item>
        <el-form-item label="新密码:" prop="newPass">
            <el-input
                size="medium"
                placeholder="请输入新密码"
                show-word-limit
                :maxlength="24"
                v-model.trim="formData.newPass"
            />
        </el-form-item>
        <el-form-item label="确认密码:" prop="dNewPass">
            <el-input
                size="medium"
                placeholder="请输入新密码"
                show-word-limit
                :maxlength="24"
                v-model.trim="formData.dNewPass"
            />
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit">修改</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import { mapGetters } from 'vuex'
import { modStaffPassword } from '@/js/api/staffApi.js'

export default {
    computed: { ...mapGetters({ staffInfo: 'userInfo' }) },
    data() {
        const _validatePass = (rule, value, callback) => {
            if (value && value.length < 6) {
                callback(new Error('密码最少6位'))
            } else if (value && value.indexOf(' ') > -1) {
                callback(new Error('密码禁止输入空格'))
            } else {
                callback()
            }
        }

        const _validateNewPass = (rule, value, callback) => {
            if (value && this.formData.newPass !== value) {
                callback(new Error('两次输入密码不一致'))
            } else {
                callback()
            }
        }
        return {
            formData: {
                oldPass: '',
                newPass: '',
                dNewPass: '',
            },
            rules: {
                oldPass: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
                newPass: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { validator: _validatePass, trigger: 'blur' },
                ],
                dNewPass: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: _validateNewPass, trigger: 'blur' },
                ],
            },
        }
    },
    methods: {
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    modStaffPassword({
                        oldPassword: this.formData.oldPass,
                        newPassword: this.formData.newPass,
                        staffId: this.staffInfo.id,
                    }).then(() => {
                        const _this = this
                        this.$message({
                            type: 'success',
                            message: '修改密码成功，请重新登录',
                            duration: 1500,
                            onClose() {
                                _this.$store.dispatch('user/logout')
                            },
                        })
                    })
                }
            })
        },
    },
}
</script>
