<template>
    <div class="main">
        <header class="header">
            <div class="logo" :style="`width:${menuCollapse ? 0 : '200px'}`">
                <img :src="logoImg" />
            </div>
            <div class="operation" @click="menuCollapse = !menuCollapse">
                <i class="el-icon-s-operation" />
            </div>
            <div class="container"></div>
            <div class="account">
                <el-dropdown @command="onHandleCommand" :show-timeout="10">
                    <el-link class="user" type="primary">
                        <span>{{ userInfo.name || '' }}</span>
                        <i class="el-icon-arrow-down el-icon--right" />
                    </el-link>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="staffInfo">个人中心</el-dropdown-item>
                        <el-dropdown-item command="logout">
                            <span style="color: #f56c6c">退出登录</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </header>

        <section>
            <el-menu
                class="custom_menu"
                :default-active="activeMenu || routeList[0]['path']"
                router
                :collapse="menuCollapse"
                :style="`width:${menuCollapse ? '72px' : '200px'}`"
                :collapse-transition="false"
            >
                <template v-for="item in routeList">
                    <el-submenu :index="item.path" :key="item.name" v-if="item.meta.subRouter && item.meta.menu">
                        <template slot="title">
                            <i :class="item.meta.icon"></i>
                            <span slot="title" class="menu_title">{{ item.meta.title }}</span>
                        </template>

                        <template v-for="sub1 in item.children">
                            <el-submenu
                                :index="sub1.path"
                                :key="sub1.path"
                                v-if="sub1.meta.subRouter && item.meta.menu"
                            >
                                <template slot="title">
                                    <span class="sub1_title">{{ sub1.meta.title }}</span>
                                </template>
                                <template v-for="sub2 in sub1.children">
                                    <el-menu-item :key="sub2.path" class="sub2_menu" :index="sub2.path">
                                        {{ sub2.meta.title }}
                                    </el-menu-item>
                                </template>
                            </el-submenu>
                            <el-menu-item
                                :key="sub1.path"
                                v-else-if="sub1.meta.menu"
                                class="sub1_menu"
                                :index="sub1.path"
                            >
                                {{ sub1.meta.title }}
                            </el-menu-item>
                        </template>
                    </el-submenu>

                    <el-menu-item :index="item.path" :key="item.name" v-if="!item.meta.subRouter && item.meta.menu">
                        <i :class="item.meta.icon"></i>
                        <span slot="title" class="menu_title">{{ item.meta.title }}</span>
                    </el-menu-item>
                </template>
            </el-menu>
            <div class="content" :style="`width: calc(100% - ${menuCollapse ? '72px' : '200px'})`">
                <div class="route_name" v-if="breadcrumbData.length !== 0">
                    <el-breadcrumb>
                        <el-breadcrumb-item v-for="i in breadcrumbData" :key="i">{{ i }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div
                    class="view_container"
                    :style="`height: calc(100% - ${breadcrumbData.length !== 0 ? '36px' : '0px'})`"
                >
                    <router-view></router-view>
                </div>
            </div>
        </section>
        <StaffInfo :isShow.sync="isShowStaffInfo" />
    </div>
</template>

<script>
import _config from '@/js/config.js'
import StaffInfo from '@/views/components/staffInfo/InfoDrawer'
import { getUserRouter } from '@/router/auth.js'
import { mapGetters } from 'vuex'

const userRouter = getUserRouter()
export default {
    components: { StaffInfo },
    computed: {
        ...mapGetters(['userInfo']),
        breadcrumbData: function () {
            const _list = []
            this.$route.matched.forEach((item) => {
                if (item.meta.title) _list.push(item.meta.title)
            })
            return _list
        },
    },
    data() {
        return {
            logoImg: _config.logo16_9,

            routeList: userRouter,
            activeMenu: userRouter[0]['path'],
            menuCollapse: false,
            isShowStaffInfo: false,
        }
    },
    watch: {
        $route: function (to) {
            if (to.meta.menu) {
                this.activeMenu = to.path
            }
        },
    },
    created() {
        this.activeMenu = this.$route.path

        // 初始化基本数据
        this.$store.dispatch('global/getCmsConfig')
    },
    mounted() {},
    methods: {
        onHandleCommand(command) {
            if (command === 'staffInfo') {
                this.isShowStaffInfo = true
            } else if (command === 'logout') {
                this.$store.dispatch('user/logout')
            }
        },
    },
}
</script>
<style lang="less" scoped>
.main {
    height: 100vh;
}
.header {
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    height: 64px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgba(214, 225, 232, 0.49);
    display: flex;
    align-items: center;
    .logo {
        width: 200px;
        height: 100%;
        flex-shrink: 0;
        overflow: hidden;
        border-right: 1px solid #e6e6e6;
        box-sizing: border-box;
        img {
            height: 100%;
            display: block;
            margin: 0 auto;
        }
    }
    .operation {
        height: 100%;
        width: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #333;
        flex-shrink: 0;
        cursor: pointer;
    }
    .container {
        flex-grow: 1;
    }
    .account {
        padding: 0 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-evenly;
        .user {
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
        }
    }
}

section {
    height: calc(100% - 64px);
    overflow: hidden;
    display: flex;
    position: relative;
}

.content {
    height: 100%;
    overflow: hidden;
    background: #f6f7f9;
    .route_name {
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        /deep/ .el-breadcrumb {
            .el-breadcrumb__inner {
                font-weight: 700;
                color: #303133;
            }
            .el-breadcrumb__separator {
                margin: 0 4px;
            }
            .el-breadcrumb__item:last-child {
                .el-breadcrumb__inner {
                    font-weight: 400;
                    color: #606266;
                }
            }
        }
    }
    .view_container {
        width: 100%;
        overflow-y: auto;
        padding: 0 24px;
        box-sizing: border-box;
    }
}
</style>
<style lang="less">
.custom_menu {
    overflow-y: auto;
    width: 200px;
    box-sizing: border-box;
    flex-shrink: 0;
    .el-menu-item {
        padding-left: 24px !important;
        min-width: 120px;
        i {
            font-size: 28px;
            margin-right: 18px;
        }
        &.is-active {
            color: #409eff;
            i {
                color: #409eff;
            }
        }
    }
    .el-submenu__title {
        padding-left: 24px !important;
        i {
            font-size: 28px;
            margin-right: 18px;
        }
        .el-submenu__icon-arrow {
            font-size: 16px;
            margin-right: 0;
        }
    }
    .sub1_menu {
        padding-left: 64px !important;
        font-size: 14px;
        font-weight: 600;
        &.el-menu-item {
            color: #888;
        }
        &.is-active {
            color: #409eff;
        }
    }
    .sub2_menu {
        padding-left: 88px !important;
        font-size: 14px;
        font-weight: 600;
        &.el-menu-item {
            color: #888;
        }
        &.is-active {
            color: #409eff;
        }
    }
    .menu_title {
        font-size: 15px;
        font-weight: 600;
    }
    .sub1_title {
        font-size: 15px;
        font-weight: 600;
        color: #888;
        padding-left: 40px;
    }
    &.el-menu--collapse {
        .el-submenu__title {
            padding-left: 20px !important;
        }
    }
}
</style>
